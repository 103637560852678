@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rounded-card {
  padding-top: 10px;
  border-radius: 15px;
  /* Anda boleh menukar nilai ini mengikut keperluan */
  overflow: hidden;
  /* Untuk mengelakkan konten melimpah */
  margin-bottom: 10px;
  /* Untuk memberikan jarak antara kad */
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.rounded-card-1 {
  display: flex;
  flex-direction: column;
  /* Lay out image and text in a row */
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  text-align: center;
  /* Center text */
  width: 100%;
  /* Adjust width as needed */
  max-width: 100px;
  /* Adjust max width as needed */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ddd;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  overflow: hidden;
  /* Ensure no overflow */
  padding: 10px;
  /* Optional: Add padding for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.rounded-card-2 {
  display: flex;
  flex-direction: row;
  /* Lay out image and text in a row */
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  text-align: center;
  /* Center text */
  width: 100%;
  height: 100%;
  /* Adjust width as needed */
  max-width: 70px;
  /* Adjust max width as needed */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ffffff;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.blue-outline-button {
  border: 2px solid hsl(0, 0%, 100%);
  color: #009976;
  padding: 2px 10px;
  /* Padding untuk butang */
  font-size: 12px;
  /* Saiz fon untuk butang */
  align-self: center;
  /* Menetapkan butang di tengah secara vertikal */
  flex-shrink: 0;
  /* Menghalang butang daripada mengecil */
  border-radius: 25px;
  background-color: hsl(240, 100%, 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  /* Bayangan butang */
  font-weight: bold;
}

.rounded-background {
  background-color: rgb(248, 230, 214);
  /* Warna latar belakang peach */
  border-radius: 7px;
  /* Bulatkan sudut */
  padding: 5px 10px;
  /* Padding untuk teks dan ikon */
  display: flex;
  /* Flexbox untuk menyusun ikon dan teks */
  align-items: center;
  /* Selaraskan secara vertikal */
  font-weight: bold;
}

.rounded-background .fa-clock {
  margin-right: 8px;
  /* Jarak antara ikon dan teks */
  color: rgb(192, 127, 6);
  /* Warna ikon */
}

.promotion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-width: 300px;
}

.promotion-card {
  width: 100%;
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.promotion-image {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.promotion-details {
  padding: 20px;
}

.promotion-details h3 {
  margin: 0;
  font-size: 1.3em;
}

.notification {
  border-radius: 5px;
  /* Make it circular */
  background-color: rgb(253, 231, 190);
  /* Ensure background is white */
  color: rgb(255, 153, 0);
  /* Text color */
  font-weight: bolder;
  /* Make text bold */
  font-size: 1vh;
  margin-top: 10px;
}

.slider-card {
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.slider-details {
  margin-top: 10px;
}

.slider-details h3 {
  margin: 0;
  font-size: 1em;
}

.slider-details p {
  margin: 10px 0 0;
  font-size: 1em;
  color: #065fc7;
}

.catalogue-container {
  display: flex;
  justify-content: flex-start;
  height: 8vh;
  grid-gap: 10px;
  gap: 10px;
  /* Add some space between the cards */
}

.rounded-card-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3vh;
  box-sizing: border-box;
  width: 12rem;
  /* Adjust width to fit content */
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.back-button--material__icon {
  fill: #fff !important;
  /* Ensure the color is applied */
}

ons-input .text-input,
ons-search-input .search-input {
  width: 100%;
  display: inline-block;
  border-radius: 15px;
}

.scrollable-container {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.filter-button.active {
  background-color: #007BFF;
  color: #FFFFFF;
}
.sliding-tab-container {
  width: 100%;
}

/* Styling the tab header for scrolling */
.tab-header {
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  padding: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  grid-gap: 20px;
  gap: 20px;
}

/* Custom scrollbar styling */
.tab-header::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.tab-header::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.tab-header::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb with opacity */
  border-radius: 14px; /* Rounded corners */
}

.tab-header::-webkit-scrollbar-thumb:hover {
  background-color: transparent; /* Darker thumb on hover */
}

.tab-title {
  white-space: nowrap;
  padding: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid transparent;
  margin: 0 05px;
  flex-shrink: 0;
}

.tab-title.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}


.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}

@media (max-width: 600px) {
  .tab-title {
    font-size: 14px;
    padding: 8px 15px;
  }


}

.page {
    font-family: Arial, sans-serif;
}

.center {
    font-size: 20px;
}

.button-quiet {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.content {
    padding: 16px;
}

.points-card {
    background-color: white;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.points {
    font-size: 24px;
}

.last-updated {
    font-size: 12px;
    color: #666;
}

.expiration {
    background-color: #FFE4B3;
    padding: 8px;
    margin-top: 8px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.tab {
    flex: 1 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
}

.tab.active {
    border-bottom: 2px solid #ffa600;
    font-weight: bold;
}

.tab-content {
    padding: 116px;
    text-align: center;

}

.gift-icon {
    width: 100px;
    margin: auto;
}

.rounded-card {
    border-radius: 15px;
    /* Anda boleh menukar nilai ini mengikut keperluan */
    overflow: hidden;
    /* Untuk mengelakkan konten melimpah */
    margin-bottom: 16px;
    /* Untuk memberikan jarak antara kad */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-button--material__icon {
    fill: #fff !important; /* Ensure the color is applied */
}

.purchase-history-container {
    padding: 20px;
}

.purchase-history-cards {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.purchase-history-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 500px;
    border: 1px solid #ddd;
}

.card-header h4 {
    margin: 0;
    color: #333;
}

.card-body p {
    margin: 5px 0;
    color: #555;
}

.no-history {
    text-align: center;
    padding: 20px;
}

.no-history-image {
    width: 150px;
    height: auto;
    border-radius: 10px;
}

.no-history h3 {
    margin-top: 20px;
    color: #333;
}

.no-history p {
    color: #555;
}

.voucher-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .voucher-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .voucher-logo {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
  
  .voucher-details {
    flex: 1 1;
  }
  
  .promo-code {
    font-size: 12px;
    color: #888;
    margin-bottom: 4px;
  }
  
  .voucher-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .voucher-date {
    font-size: 12px;
    color: #666;
  }
  
  .voucher-status {
    text-align: right;
  }
  
  .status-text {
    background-color: #ddd;
    color: #666;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 4px;
  }
  
  .free-item {
    font-size: 14px;
    font-weight: bold;
  }
  
.redeem-requests-page {
  padding: 15px;
}

.requests-list {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.request-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.request-card:hover {
  transform: translateY(-5px);
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.request-id {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.status {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.status.pending {
  color: #ffa600;
}

.status.success {
  color: #28a745;
}

.status.failed {
  color: #dc3545;
}

.request-body {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.request-info {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.back-button__icon {
  fill: #fff !important;
}

.reward-details-page {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    padding-bottom: 80px; /* Add bottom padding to make space for the fixed footer */
  }
  
  .image-row img {
    width: 100%;
    height: auto;
  }
  
  .info-row, .how-it-works-row, .more-details-row, .terms-conditions-row, .faq-row {
    margin-bottom: 20px;
  }
  
  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f8f9fa;
    padding: 10px;
    border-top: 1px solid #ddd;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  }
  
  .fixed-footer p {
    margin: 5px 0;
  }
  
  .fixed-footer button {
    padding: 10px 20px;
    background-color: #009976;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .fixed-footer button:hover {
    background-color: #0056b3;
  }
  
/* Base styles */
.profile-page {
  height: 100%;
  overflow-y: auto;
  background-image: url(/static/media/bg_profile.e8dd5834.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-header {
  background-color: #eef1f5;
  border-radius: 15px;
  margin: 25px;
  padding: 25px;
  margin-bottom: 20px;
  background-image: url(/static/media/logo_frame.06b6ba7c.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: Poppins, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile-details {
  flex-grow: 1;
  margin-left: 15px;
}

.profile-name {
  font-size: 20px;
  margin: 0;
}

.profile-id {
  color: #6c757d;
}

.profile-icons .icon-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.profile-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vh;
}

.balance-info {
  display: flex;
  align-items: center;
}

.balance-title {
  font-size: 14px;
  color: #6c757d;
}

.balance-amount {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.topup-button {
  background-color: #ffffff;
  color: #6c63ff;
  border: none;
  border-radius: 15px;
  padding: 5px 15px;
  margin-left: 20px;
  cursor: pointer;
}

.points-info {
  text-align: right;
}

.points {
  font-size: 14px;
}

.points-extra {
  color: #ff9800;
}

.tier {
  font-size: 14px;
  color: #6c757d;
}

.profile-nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  margin: 0 5px; /* Space between cards */
  text-align: center;
  font-size: 14px;
  color: #333;
}

.nav-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  background-color: #eef1f5;
  border-radius: 10px;
}

.nav-card i {
  font-size: 20px;
  color: #666;
}

.nav-item span {
  color: #313131;
  font-family: Montserrat, sans-serif;
}

.reward-section {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  margin-inline: 10%;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(32, 32, 32, 0.2);
}

.reward-banner {
  display: flex;
  align-items: center;
}

.reward-image {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  margin-right: 15px; /* Space between image and text */
  border-radius: 50%; /* Optional: makes the image round */
}

.reward-text {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
}

.reward-text p:first-child {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.reward-text p:last-child {
  font-size: 14px;
  color: #666;
}

.options-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  font-family: Poppins, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.option-icon {
  margin-right: 10px;
  font-size: 16px; /* Adjust size as needed */
}

.option-item i {
  font-size: 14px;
  color: #999;
}

.option-chevron {
  margin-left: auto;
  font-size: 16px; /* Adjust size as needed */
}

.profile-footer {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  font-family: Montserrat, sans-serif;
  box-shadow: 0 4px 8px rgba(32, 32, 32, 0.2);
}

.footer-item {
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .profile-header {
    margin: 25px;
    padding: 25px;
  }

  .profile-avatar img {
    width: 50px;
    height: 50px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-id {
    font-size: 12px;
  }

  .balance-amount {
    font-size: 20px;
  }

  .topup-button {
    padding: 10px 10px;
    margin-left: 10px;
  }

  .nav-card {
    width: 40px;
    height: 40px;
  }

  .nav-card i {
    font-size: 16px;
  }

  .reward-image {
    width: 80px;
    height: 80px;
  }

  .reward-text p:first-child {
    font-size: 14px;
  }

  .reward-text p:last-child {
    font-size: 12px;
  }

  .option-item {
    padding: 8px;
  }

  .option-icon {
    font-size: 14px;
  }

  .option-chevron {
    font-size: 14px;
  }

  .footer-item {
    font-size: 12px;
  }
}

/* Media Queries for Small Screens (320px width) */
@media (max-width: 420px) {
  .profile-header {
    margin: 25px;
    padding: 25px;
  }

  .profile-avatar img {
    width: 50px;
    height: 50px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-id {
    font-size: 12px;
  }

  .balance-amount {
    font-size: 20px;
  }

  .topup-button {
    padding: 10px 10px;
    margin-left: 10px;
  }

  .nav-card {
    width: 40px;
    height: 40px;
  }

  .nav-card i {
    font-size: 16px;
  }

  .reward-image {
    width: 80px;
    height: 80px;
  }

  .reward-text p:first-child {
    font-size: 14px;
  }

  .reward-text p:last-child {
    font-size: 12px;
  }

  .option-item {
    padding: 8px;
  }

  .option-icon {
    font-size: 14px;
  }

  .option-chevron {
    font-size: 14px;
  }

  .footer-item {
    font-size: 12px;
  }
}

/* Media Queries for Small Screens (320px width) */
@media (max-width: 320px) {
  .profile-header {
    margin: 20px;
    padding: 20px;
  }

  .profile-avatar img {
    width: 40px;
    height: 40px;
  }

  .profile-name {
    font-size: 16px;
  }

  .profile-id {
    font-size: 10px;
  }

  .balance-amount {
    padding: 12px;
    font-size: 3vh;
  }

  .topup-button {
    padding: 5px 12px;
    margin-left: 5px;
    font-size: 8px;
  }

  .points-info {
    font-size: 1px;
  }

  .nav-card {
    width: 30px;
    height: 30px;
  }

  .nav-card i {
    font-size: 14px;
  }

  .reward-image {
    width: 60px;
    height: 60px;
  }

  .reward-text p:first-child {
    font-size: 12px;
  }

  .reward-text p:last-child {
    font-size: 10px;
  }

  .option-item {
    padding: 5px;
  }

  .option-icon {
    font-size: 12px;
  }

  .option-chevron {
    font-size: 12px;
  }

  .footer-item {
    font-size: 10px;
  }
}

