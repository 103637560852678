.rounded-card {
  padding-top: 10px;
  border-radius: 15px;
  /* Anda boleh menukar nilai ini mengikut keperluan */
  overflow: hidden;
  /* Untuk mengelakkan konten melimpah */
  margin-bottom: 10px;
  /* Untuk memberikan jarak antara kad */
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.rounded-card-1 {
  display: flex;
  flex-direction: column;
  /* Lay out image and text in a row */
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  text-align: center;
  /* Center text */
  width: 100%;
  /* Adjust width as needed */
  max-width: 100px;
  /* Adjust max width as needed */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ddd;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  overflow: hidden;
  /* Ensure no overflow */
  padding: 10px;
  /* Optional: Add padding for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.rounded-card-2 {
  display: flex;
  flex-direction: row;
  /* Lay out image and text in a row */
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  text-align: center;
  /* Center text */
  width: 100%;
  height: 100%;
  /* Adjust width as needed */
  max-width: 70px;
  /* Adjust max width as needed */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ffffff;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.blue-outline-button {
  border: 2px solid hsl(0, 0%, 100%);
  color: #009976;
  padding: 2px 10px;
  /* Padding untuk butang */
  font-size: 12px;
  /* Saiz fon untuk butang */
  align-self: center;
  /* Menetapkan butang di tengah secara vertikal */
  flex-shrink: 0;
  /* Menghalang butang daripada mengecil */
  border-radius: 25px;
  background-color: hsl(240, 100%, 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  /* Bayangan butang */
  font-weight: bold;
}

.rounded-background {
  background-color: rgb(248, 230, 214);
  /* Warna latar belakang peach */
  border-radius: 7px;
  /* Bulatkan sudut */
  padding: 5px 10px;
  /* Padding untuk teks dan ikon */
  display: flex;
  /* Flexbox untuk menyusun ikon dan teks */
  align-items: center;
  /* Selaraskan secara vertikal */
  font-weight: bold;
}

.rounded-background .fa-clock {
  margin-right: 8px;
  /* Jarak antara ikon dan teks */
  color: rgb(192, 127, 6);
  /* Warna ikon */
}

.promotion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-width: 300px;
}

.promotion-card {
  width: 100%;
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.promotion-image {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.promotion-details {
  padding: 20px;
}

.promotion-details h3 {
  margin: 0;
  font-size: 1.3em;
}

.notification {
  border-radius: 5px;
  /* Make it circular */
  background-color: rgb(253, 231, 190);
  /* Ensure background is white */
  color: rgb(255, 153, 0);
  /* Text color */
  font-weight: bolder;
  /* Make text bold */
  font-size: 1vh;
  margin-top: 10px;
}

.slider-card {
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.slider-details {
  margin-top: 10px;
}

.slider-details h3 {
  margin: 0;
  font-size: 1em;
}

.slider-details p {
  margin: 10px 0 0;
  font-size: 1em;
  color: #065fc7;
}

.catalogue-container {
  display: flex;
  justify-content: flex-start;
  height: 8vh;
  gap: 10px;
  /* Add some space between the cards */
}

.rounded-card-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3vh;
  box-sizing: border-box;
  width: 12rem;
  /* Adjust width to fit content */
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
