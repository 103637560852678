.reward-details-page {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    padding-bottom: 80px; /* Add bottom padding to make space for the fixed footer */
  }
  
  .image-row img {
    width: 100%;
    height: auto;
  }
  
  .info-row, .how-it-works-row, .more-details-row, .terms-conditions-row, .faq-row {
    margin-bottom: 20px;
  }
  
  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f8f9fa;
    padding: 10px;
    border-top: 1px solid #ddd;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  }
  
  .fixed-footer p {
    margin: 5px 0;
  }
  
  .fixed-footer button {
    padding: 10px 20px;
    background-color: #009976;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .fixed-footer button:hover {
    background-color: #0056b3;
  }
  