.sliding-tab-container {
  width: 100%;
}

/* Styling the tab header for scrolling */
.tab-header {
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  padding: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  gap: 20px;
}

/* Custom scrollbar styling */
.tab-header::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.tab-header::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.tab-header::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb with opacity */
  border-radius: 14px; /* Rounded corners */
}

.tab-header::-webkit-scrollbar-thumb:hover {
  background-color: transparent; /* Darker thumb on hover */
}

.tab-title {
  white-space: nowrap;
  padding: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid transparent;
  margin: 0 05px;
  flex-shrink: 0;
}

.tab-title.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}


.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}

@media (max-width: 600px) {
  .tab-title {
    font-size: 14px;
    padding: 8px 15px;
  }


}
