.voucher-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .voucher-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .voucher-logo {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
  
  .voucher-details {
    flex: 1;
  }
  
  .promo-code {
    font-size: 12px;
    color: #888;
    margin-bottom: 4px;
  }
  
  .voucher-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .voucher-date {
    font-size: 12px;
    color: #666;
  }
  
  .voucher-status {
    text-align: right;
  }
  
  .status-text {
    background-color: #ddd;
    color: #666;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 4px;
  }
  
  .free-item {
    font-size: 14px;
    font-weight: bold;
  }
  