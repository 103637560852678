.redeem-requests-page {
  padding: 15px;
}

.requests-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.request-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.request-card:hover {
  transform: translateY(-5px);
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.request-id {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;
}

.status {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}

.status.pending {
  color: #ffa600;
}

.status.success {
  color: #28a745;
}

.status.failed {
  color: #dc3545;
}

.request-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.request-info {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-button__icon {
  fill: #fff !important;
}
