.page {
    font-family: Arial, sans-serif;
}

.center {
    font-size: 20px;
}

.button-quiet {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.content {
    padding: 16px;
}

.points-card {
    background-color: white;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.points {
    font-size: 24px;
}

.last-updated {
    font-size: 12px;
    color: #666;
}

.expiration {
    background-color: #FFE4B3;
    padding: 8px;
    margin-top: 8px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.tab {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
}

.tab.active {
    border-bottom: 2px solid #ffa600;
    font-weight: bold;
}

.tab-content {
    padding: 116px;
    text-align: center;

}

.gift-icon {
    width: 100px;
    margin: auto;
}

.rounded-card {
    border-radius: 15px;
    /* Anda boleh menukar nilai ini mengikut keperluan */
    overflow: hidden;
    /* Untuk mengelakkan konten melimpah */
    margin-bottom: 16px;
    /* Untuk memberikan jarak antara kad */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-button--material__icon {
    fill: #fff !important; /* Ensure the color is applied */
}

.purchase-history-container {
    padding: 20px;
}

.purchase-history-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.purchase-history-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 500px;
    border: 1px solid #ddd;
}

.card-header h4 {
    margin: 0;
    color: #333;
}

.card-body p {
    margin: 5px 0;
    color: #555;
}

.no-history {
    text-align: center;
    padding: 20px;
}

.no-history-image {
    width: 150px;
    height: auto;
    border-radius: 10px;
}

.no-history h3 {
    margin-top: 20px;
    color: #333;
}

.no-history p {
    color: #555;
}
