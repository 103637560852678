/* Base styles */
.profile-page {
  height: 100%;
  overflow-y: auto;
  background-image: url("../image/bg_profile.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-header {
  background-color: #eef1f5;
  border-radius: 15px;
  margin: 25px;
  padding: 25px;
  margin-bottom: 20px;
  background-image: url("../image/logo_frame.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: Poppins, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile-details {
  flex-grow: 1;
  margin-left: 15px;
}

.profile-name {
  font-size: 20px;
  margin: 0;
}

.profile-id {
  color: #6c757d;
}

.profile-icons .icon-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.profile-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vh;
}

.balance-info {
  display: flex;
  align-items: center;
}

.balance-title {
  font-size: 14px;
  color: #6c757d;
}

.balance-amount {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.topup-button {
  background-color: #ffffff;
  color: #6c63ff;
  border: none;
  border-radius: 15px;
  padding: 5px 15px;
  margin-left: 20px;
  cursor: pointer;
}

.points-info {
  text-align: right;
}

.points {
  font-size: 14px;
}

.points-extra {
  color: #ff9800;
}

.tier {
  font-size: 14px;
  color: #6c757d;
}

.profile-nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 5px; /* Space between cards */
  text-align: center;
  font-size: 14px;
  color: #333;
}

.nav-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  background-color: #eef1f5;
  border-radius: 10px;
}

.nav-card i {
  font-size: 20px;
  color: #666;
}

.nav-item span {
  color: #313131;
  font-family: Montserrat, sans-serif;
}

.reward-section {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  margin-inline: 10%;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(32, 32, 32, 0.2);
}

.reward-banner {
  display: flex;
  align-items: center;
}

.reward-image {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  margin-right: 15px; /* Space between image and text */
  border-radius: 50%; /* Optional: makes the image round */
}

.reward-text {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
}

.reward-text p:first-child {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.reward-text p:last-child {
  font-size: 14px;
  color: #666;
}

.options-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  font-family: Poppins, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.option-icon {
  margin-right: 10px;
  font-size: 16px; /* Adjust size as needed */
}

.option-item i {
  font-size: 14px;
  color: #999;
}

.option-chevron {
  margin-left: auto;
  font-size: 16px; /* Adjust size as needed */
}

.profile-footer {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  font-family: Montserrat, sans-serif;
  box-shadow: 0 4px 8px rgba(32, 32, 32, 0.2);
}

.footer-item {
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .profile-header {
    margin: 25px;
    padding: 25px;
  }

  .profile-avatar img {
    width: 50px;
    height: 50px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-id {
    font-size: 12px;
  }

  .balance-amount {
    font-size: 20px;
  }

  .topup-button {
    padding: 10px 10px;
    margin-left: 10px;
  }

  .nav-card {
    width: 40px;
    height: 40px;
  }

  .nav-card i {
    font-size: 16px;
  }

  .reward-image {
    width: 80px;
    height: 80px;
  }

  .reward-text p:first-child {
    font-size: 14px;
  }

  .reward-text p:last-child {
    font-size: 12px;
  }

  .option-item {
    padding: 8px;
  }

  .option-icon {
    font-size: 14px;
  }

  .option-chevron {
    font-size: 14px;
  }

  .footer-item {
    font-size: 12px;
  }
}

/* Media Queries for Small Screens (320px width) */
@media (max-width: 420px) {
  .profile-header {
    margin: 25px;
    padding: 25px;
  }

  .profile-avatar img {
    width: 50px;
    height: 50px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-id {
    font-size: 12px;
  }

  .balance-amount {
    font-size: 20px;
  }

  .topup-button {
    padding: 10px 10px;
    margin-left: 10px;
  }

  .nav-card {
    width: 40px;
    height: 40px;
  }

  .nav-card i {
    font-size: 16px;
  }

  .reward-image {
    width: 80px;
    height: 80px;
  }

  .reward-text p:first-child {
    font-size: 14px;
  }

  .reward-text p:last-child {
    font-size: 12px;
  }

  .option-item {
    padding: 8px;
  }

  .option-icon {
    font-size: 14px;
  }

  .option-chevron {
    font-size: 14px;
  }

  .footer-item {
    font-size: 12px;
  }
}

/* Media Queries for Small Screens (320px width) */
@media (max-width: 320px) {
  .profile-header {
    margin: 20px;
    padding: 20px;
  }

  .profile-avatar img {
    width: 40px;
    height: 40px;
  }

  .profile-name {
    font-size: 16px;
  }

  .profile-id {
    font-size: 10px;
  }

  .balance-amount {
    padding: 12px;
    font-size: 3vh;
  }

  .topup-button {
    padding: 5px 12px;
    margin-left: 5px;
    font-size: 8px;
  }

  .points-info {
    font-size: 1px;
  }

  .nav-card {
    width: 30px;
    height: 30px;
  }

  .nav-card i {
    font-size: 14px;
  }

  .reward-image {
    width: 60px;
    height: 60px;
  }

  .reward-text p:first-child {
    font-size: 12px;
  }

  .reward-text p:last-child {
    font-size: 10px;
  }

  .option-item {
    padding: 5px;
  }

  .option-icon {
    font-size: 12px;
  }

  .option-chevron {
    font-size: 12px;
  }

  .footer-item {
    font-size: 10px;
  }
}
