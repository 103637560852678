.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.back-button--material__icon {
  fill: #fff !important;
  /* Ensure the color is applied */
}

ons-input .text-input,
ons-search-input .search-input {
  width: 100%;
  display: inline-block;
  border-radius: 15px;
}

.scrollable-container {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.filter-button.active {
  background-color: #007BFF;
  color: #FFFFFF;
}